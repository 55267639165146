@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.products-container {
  padding: 10px;
  margin-top: -25px;
  @media (max-width: $xxl) {
    margin-top: -55px;
  }
  @media (max-width: $lg) {
    margin-top: 0;
  }
  .filter-button {
    display: none;
  }

  .products-list-wrapper {
    width: 100%;
  }

  @media (max-width: $lg) {
    padding: 30px;
    .filter-button {
      display: block;
      width: 50px;
      height: 50px;
      position: fixed;
      bottom: 30px;
      right: 40px;
      border-radius: 25px !important;
      padding: 0px !important;
      z-index: 2;
    }
  }
  @media (max-width: $md) {
    padding: 0;
  }
  @media (max-width: $xs) {
    .filter-button {
      bottom: 25px;
      right: 25px;
    }
  }
}
