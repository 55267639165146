@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-select-selection-search {
  input {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    &:lang(ar) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 22px !important;
      border-bottom-right-radius: 22px !important;
    }
  }
  .ant-input-search-button,
  .ant-input-group-addon {
    border-radius: 0 22px 22px 0 !important;
    &:lang(ar) {
      border-radius: 22px 0 0 22px !important;
    }
  }
  .ant-btn-primary {
    background-color: $lightGray;
    border-color: $lightGray;
    svg {
      color: $primary;
    }
  }
}
@media (max-width: $md) {
  .ant-input-search-small .ant-input-search-button {
    height: 22px;
    margin-top: -10px;
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
  }
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border-color: #d9d9d9;
  }
}
