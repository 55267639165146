@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.cart {
  padding-bottom: 30px;
  margin: 0 !important;
  .product-preview {
    margin-bottom: 30px;
  }
  .ant-col {
    padding: 0 !important;
  }
  .product-preview:last-child {
    margin-bottom: 0;
  }
  .continue-shopping {
    font-size: 16px;
    font-weight: 600;
    color: $primary;
    border-color: $primary;
    border-radius: 5px !important;
    margin-top: 50px;
    padding: 10px 90px;
    @media (max-width: $xxl) {
      padding: 10px 65px;
    }
    @media (max-width: $sm) {
      padding: 10px 30px;
    }
    &:hover {
      color: $white;
      background-color: $primary;
    }
  }
  .products-list {
    margin-top: 10px;
  }
  @media (max-width: $lg) {
    .continue-shopping-wrapper {
      display: flex;
      justify-content: center;
      padding: 10px 45px;
    }
  }
}

.cart-summary {
  .ant-space {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .price-container {
    .ant-space {
      justify-content: flex-start;
    }
  }
  .ant-divider.ant-divider-horizontal {
    width: 100%;
    margin: 0;
  }

  .summary-action-buttons {
    flex-wrap: wrap;
  }
}
