@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.address-tab-wrapper {
  .select-address {
    color: $fontDark;
    font-size: 20px;
    font-weight: 500;
    margin-top: -50px;
    margin-bottom: 25px;
    @media (max-width: $xxl) {
      font-size: 18px;
    }
    @media (max-width: $lg) {
      margin-top: 0;
    }
  }
  .my-addresses {
    font-size: 20px;
    color: $fontDark;
    font-weight: 600;
    margin: 0 5px 20px;
    @media (max-width: $xxl) {
      font-size: 18px;
    }
  }
  .address-tab {
    width: 100%;
    .addresses-list {
      margin: 0 5px 10px;
    }
    .address-actions {
      display: flex;
      align-items: baseline;
      column-gap: 5px;
      .confirm-btn {
        background-color: $greenButton;
        text-transform: uppercase;
        color: $white;
        border-color: $greenButton;
        border-radius: 5px !important;
        padding: 10px 70px;
        margin-top: 20px;
        &:hover,
        &:focus {
          background-color: $greenButton;
          border-color: $greenButton;
        }
      }
      @media (max-width: $sm) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .add-address-btn {
    margin: 10px 5px 0;
    padding: 10px 0;
    width: 200px;
    border-radius: 6px !important;
    background-color: $white;
    color: $primary;
    border-color: $primary;
    text-transform: uppercase;
    &:hover {
      background-color: $primary;
      color: white;
    }

    @media (max-width: $sm) {
      margin: 0 5px;
    }
  }
}
