@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.profile-header {
  background-color: $darkBlue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  padding: 7.5px 80px 7.5px 80px;
  button {
    padding: 10px !important;
  }
  .social-media-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .social-media {
      display: flex;
      align-items: baseline;
      padding: 10px;
      & > span {
        padding: 10px;
        font-size: 14px;
        color: $white;
      }

      .ant-btn {
        padding: 4px;
      }
    }
    .btn-wrapper {
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .ant-typography,
  .profile-menu-icon,
  .language-icon,
  a {
    color: $white !important;
  }
  .ant-divider-vertical {
    height: 20px;
  }
  .ant-divider-vertical {
    border-left: 1px solid $white;
  }

  .ant-menu-item-selected {
    background-color: $white !important;
  }
  @media (max-width:$xxl) {
    height: 35px;
  }
  @media (max-width: $md) {
    display: none;
  }
}

.ant-dropdown {
  .ant-menu-item {
    display: flex !important;
    justify-content: center !important;
  }
}
