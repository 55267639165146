@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

button.ant-btn.add-to-favorites {
  padding: 10px 0;
  border: 0;
  color: $fontDark;
  box-shadow: none !important;
  font-size: 16px;
  @media (max-width: $xxl) {
    font-size: 14px;
  }
}
button.ant-btn.add-to-favorites:hover {
  background-color: transparent;
  color: $primary;
}

.ant-btn.ant-btn-default.add-to-favorites {
  background-color: transparent !important;
  color: red !important;
  border: solid 0px !important;
}
