@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.counter-container {
  width: 100%;
  .ant-space-item {
    margin: 0 !important;
  }
  .ant-input-number {
    text-align: center;
    max-width: 50px;
    border-color: $primary;
    border-radius: 0px;
    border: $greyBorderColor solid 1px;
    border-width: 1px 0px;
    input {
      height: 30px;
      text-align: center;
      color: $fontDark;
      font-weight: 500;
      font-size: 16px;
    }
    .ant-btn {
      height: 32px !important;
    }
    &:hover {
      border-color: $greyBorderColor;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .ant-btn,
  .ant-btn:focus {
    border-color: $greyBorderColor;
    color: $selected;
  }
  .ant-space {
    align-items: flex-end;
    .ant-space-item {
      margin-left: 0 !important;
    }
  }
  .ant-btn-icon-only.ant-btn-sm {
    width: 40px;
    height: 32px;
  }
  .counter-btn {
    border: solid 1px;
    margin: 0px;
    background-color: $white;
    border-color: $greyBorderColor;
    &:hover {
      background-color: $selected;
      border-color: $selected;
      svg path {
        fill: $white;
      }
    }
  }
  .plus-btn {
    border-radius: 5px 0 0 5px !important;
    &:lang(ar) {
      border-radius: 0 5px 5px 0 !important;
    }
  }

  .minus-btn {
    border-radius: 0 5px 5px 0 !important;
    &:lang(ar) {
      border-radius: 5px 0 0 5px !important;
    }
  }
  @media (max-width: $md) {
    width: 95%;
  }
}
.ant-input-number-rtl .ant-input-number-input{
  text-align: center !important;
}
