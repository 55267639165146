@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.product-card-wrapper {
  .ant-space {
    column-gap: 10px;
  }
  min-height: 300px;
  width: 100%;
  display: inline-block;
  border: 1px solid $borderColor;
  border-radius: 10px;
  .ant-card-hoverable {
    border: none;
    &:hover {
      box-shadow: none;
    }
  }
  .top-card {
    display: flex;
    justify-content: space-between;
    .favorites {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: $xxl) {
        svg {
          width: 66%;
        }
      }
    }
  }
  .card-cover {
    display: flex;
    justify-content: center;
    height: 150px;
    img {
      object-fit: contain;
      max-width: -webkit-fill-available;
    }
  }
  .card-title {
    color: $fontDark;
    margin-top: 30px;
    min-height: 45px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: $xxl) {
      font-size: 14px;
      min-height: 40px;
    }
  }
  .ant-card {
    height: 100%;
    border-radius: 10px;
    .ant-card-body {
      padding: 0 15px 15px;
      .ant-divider-horizontal {
        margin: 5px 0 20px;
      }
      @media (max-width: $xs) {
        padding: 0 10px 15px;
      }
    }
  }
  .add-to-cart-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media (max-width: $xxl) {
      margin-top: 10px;
    }
  }
}
