@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.auth-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px 60px;
  background-image: url('/assets/images/backgroung.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  .header-wrapper {
    flex-wrap: nowrap;
    justify-content: space-between;
    .logo {
      width: auto;
      padding-top: 20px;
    }

    .header-title {
      color: white;
      font-weight: 200;
      font-size: 16px;
      display: flex;
      justify-content: end;
      button {
        color: white;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        padding: 0;
        border: none;
        background: none;
      }
    }
  }
  .tabs-wrapper {
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
    .section-radius {
      border-radius: 5px;
    }
    .banner-wrapper {
      display: flex;
      flex-direction: column;
      span {
        color: white;
        font-size: 40px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .subtitle {
        font-size: 16px;
        text-wrap: wrap;
      }
    }
    .tabs {
      border-radius: 5px;
      .form-wrapper {
        background-color: $white;
        padding: 20px 30px;
        .forget-password-btn {
          padding: 0;
        }
        .ant-col {
          padding: 0 !important;
        }
        .terms_conditions {
          color: $fontDark;
          font-size: 14px;
          a {
            color: $selected;
            text-decoration: underline;
          }
          @media (max-width: $xxl) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .copyright {
    color: white;
    padding-top: 50px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
  }
}

@media (max-width: $xxl) {
  .auth-wrapper {
    padding: 20px 40px;

    .header-wrapper {
      .logo {
        height: 100px;
      }
      .header-title {
        font-size: 14px;
        button {
          font-size: 14px;
        }
      }
    }
    .tabs-wrapper {
      .banner-wrapper {
        span {
          font-size: 34px;
        }
      }
    }
    .copyright {
      font-size: 12px;
    }
  }
}
@media (max-width: $xl) {
  .auth-wrapper {
    .tabs-wrapper {
      .banner-wrapper {
        span {
          font-size: 25px;
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .auth-wrapper {
    padding: 30px 40px;
    .tabs-wrapper {
      padding-top: 30px;
    }
    .copyright {
      padding-top: 30px;
    }
  }
}
@media (max-width: '767px') {
  .auth-wrapper {
    border-radius: 20px;
    .header-wrapper {
      align-items: center;
      .logo {
        padding: 0px;
        height: 85px;
      }
      .header-title {
        margin-top: 10px;
        font-size: 14px;
        button {
          font-size: 14px;
        }
      }
    }
    .tabs-wrapper {
      margin: 0px auto;
      flex-direction: column;
      align-items: normal;
      .banner-wrapper {
        display: none;
        span {
          text-align: center;
        }
      }
      .tabs {
        margin: 20px auto;
      }
    }
  }
}

@media (max-width: $sm) {
  .auth-wrapper {
    padding: 20px;
    margin-top: 30px;
    border-radius: 10px;
    .header-wrapper {
      .logo {
        height: 55px;
      }
    }
    .tabs-wrapper {
      .tabs {
        .form-wrapper {
          padding: 10px;
        }
      }
    }
  }
}
