@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-radio-checked .ant-radio-inner {
  border-color: $selected;
}
.ant-radio-inner::after {
  background-color: $selected;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-left: 0;
}
