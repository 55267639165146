@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.single-order-wrapper {
  .single-order {
    .number-date-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
      .order-number {
        color: $fontDark;
        font-size: 30px;
        font-weight: bold;
      }
      .order-date {
        color: $selected;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .order-wrapper {
      .section-radius {
        border-radius: 5px;
      }
      .datails-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .ant-typography {
          color: $fontDark;
        }
      }
      .products-wrapper {
        .ant-space {
          width: 100%;
        }
        .orders-list-wrapper {
          .ant-space-item {
            max-height: 410px;
            overflow-y: auto;
            .ant-list{
              padding: 0 10px;
            }
          }
          .custom-product-name {
            width: 100%;
            white-space: unset;
          }
        }
      }
    }
    @media (max-width: $lg) {
      .order-wrapper {
        .datails-wrapper {
          flex-direction: row;
          .bordered-section-wrapper {
            width: 50%;
          }
        }
      }
    }
    @media (max-width: $xs) {
      .number-date-wrapper {
        margin-bottom: 30px;
        .order-number {
          font-size: 22px;
        }
        .order-date {
          font-size: 14px;
        }
      }
      .order-wrapper {
        .datails-wrapper {
          flex-direction: column;
          .bordered-section-wrapper {
            width: 100%;
          }
          .ant-typography {
            font-size: 14px;
          }
        }
      }
    }
  }
  .ant-spin {
    display: flex;
    justify-content: center;
  }
}
