@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.add-to-cart {
  border-radius: 6px !important;
  width: 180px;
  text-transform: uppercase;
  margin-top: 25px;
  font-size: 14px !important;
  @media (max-width: $xxl) {
    font-size: 12px !important;
    
  }
}
.add {
  color: $white !important;
  background-color: $selected !important;
  border-color: $selected !important;
}
.remove {
  color: $selected !important;
  background-color: transparent !important;
  border-color: $selected !important;
}
.quantity-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.quantity-text {
  color: $fontDark;
  font-size: 18px;
  @media (max-width: $xxl) {
    font-size: 16px;
  }
}

.ant-space-item {
  margin-right: 0px !important;
}
