@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-checkbox:hover,
.ant-checkbox-wrapper:hover {
  .ant-checkbox-checked::after {
    border: 1px solid $selected;
  }
  .ant-checkbox-inner {
    border-color: $selected;
  }
  .ant-typography {
    color: $selected;
  }
}

.ant-checkbox-wrapper {
  .ant-typography {
    color: $fontDark;
    font-size: 18px;
    @media (max-width: $xxl) {
      font-size: 16px;
    }
  }
  .ant-checkbox-checked {
    .ant-typography {
      color: $selected;
    }
    .ant-checkbox-inner {
      background-color: $white;
      border-color: $selected;
      border-radius: 3px;
      &::after {
        border: 2px solid $selected;
        border-top: 0;
        border-left: 0;
      }
    }
  }
}

.ant-checkbox-wrapper-checked {
  .ant-typography {
    color: $selected;
  }
}

@media (max-width: $md) {
  .ant-checkbox-wrapper {
    .ant-typography {
      font-size: 18px !important;
    }
  }
}
