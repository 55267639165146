@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.hide-controller {
  transition: all 0.3s ease;
  position: relative;
  z-index: 99;
}
@media (max-width: $xxl) {
  .hide {
    .profile-header {
      height: 50px;
    }
  }
}

.to-back {
  z-index: 98;
}
