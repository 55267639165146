@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.product-preview-wrapper {
  .ant-row {
    margin: 0 !important;
    row-gap: 10px;
  }
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background: transparent;
  }
  .ant-col {
    padding: 0 !important;
    display: flex;
  }
  .details-counter {
    .ant-col {
      justify-content: flex-end;
      align-items: center;
    }

    .price-container {
      .price.ant-typography {
        font-size: 18px;
        @media (max-width: $xxl) {
          font-size: 16px;
        }
      }
      display: flex;
      align-items: center;
      .ant-space {
        flex-direction: column;
      }

      .ant-space {
        column-gap: 10px;
      }
    }
    .remove-btn {
      margin-top: 6px;
      svg:hover {
        path {
          stroke: $selected !important;
        }
      }
    }
  }
  .details-name,
  .details-counter {
    display: flex;
    align-items: center;
    padding: 0 !important;
    column-gap: 10px;

    .product-name {
      color: $fontDark;
      font-size: 16px;
      font-weight: 500;
      max-width: 300px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: $xxl) {
        font-size: 14px;
        max-width: 250px;
      }
      @media (max-width: $xxs) {
        max-width: 170px;
      }
    }
    .custom-product-name {
      width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: $xxl) {
        width: 300px;
      }
      @media (max-width: $xl) {
        width: 120px;
      }
      @media (max-width: $lg) {
        width: 350px;
      }
      @media (max-width: $sm) {
        width: 200px;
      }
      @media (max-width: '375px') {
        width: 100px;
      }
    }
  }
  .ant-card-bordered {
    border: none;
  }

  .ant-card-body {
    padding: 0;
    .product-link {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 15px;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 100px;
      @media (max-width: $xxl) {
        height: 90px;
      }
    }
  }

  @media (max-width: $lg) {
    .ant-card-body {
      img {
        width: 100%;
        min-height: 150px;
      }
    }
  }
  @media (max-width: $md) {
    .details-name {
      column-gap: 20px;
      .product-name-wrapper {
        display: flex;
        flex-direction: column;
        .price-container {
          .ant-space {
            column-gap: 10px;
          }
        }
      }
    }
    .details-counter {
      justify-content: flex-end;
    }

    .ant-card-body {
      img {
        width: 100%;
        min-height: 100px;
      }
    }
    .remove-btn-wrapper {
      justify-content: flex-end;
      .remove-btn {
        padding: 0;
        svg:hover {
          path {
            stroke: $selected !important;
          }
        }
      }
    }
  }
}

.editable-bordered-section {
  background-color: $white;
  border: 1px solid $borderColor;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
  padding-right: 30px;
  &:lang(ar) {
    padding-right: 10px;
    padding-left: 30px;
  }
  .details-name {
    h2.ant-typography {
      margin-bottom: 0;
    }
    .product-name {
      font-size: 14px;
    }
  }
  .price-container {
    .ant-typography {
      font-size: 16px;
    }
  }
  @media (max-width: $xl) {
    .details-name {
      .product-name {
        font-size: 14px;
      }
    }
    .price-container {
      .ant-typography {
        font-size: 14px;
      }
    }
  }
}
