@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.categories-header {
  background-color: $blue;
  padding: 0 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  column-gap: 10px;
  z-index: 20;
  .categories-wrapper {
    svg {
      width: 20px;
      height: 20px;
    }
    .ant-menu {
      background-color: transparent;
      border-bottom: 0px;
    }
    .ant-menu-submenu {
      margin: 0 !important;
      span {
        color: $white;
      }
    }
    .ant-menu-submenu {
      border-color: transparent !important;
    }
    .ant-menu-submenu:active {
      color: $selected;
    }
    .ant-divider-vertical {
      border-left-color: $greyBackground;
      height: 25px;
    }
    .promotionsMenu {
      display: flex;
      align-items: center;
    }
    .btn-wrapper {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
        @media (max-width: $xxl) {
          font-size: 14px;
        }
      }
    }
    .ant-btn {
      height: 100%;
      padding: 10px 5px;
      @media (max-width: $xxl) {
        padding: 0px 5px 10px;
      }
    }

    .ant-btn:hover {
      color: $selected;
      svg path {
        stroke: $selected !important;
        transform: rotate(180deg) !important;
        transform-origin: center !important;
      }
      .ant-typography,
      span {
        color: $selected;
      }
    }
    .ant-btn-text > span {
      color: $white;
    }
    .ant-btn-text > span:hover {
      color: $selected;
    }
    .ant-typography {
      color: $fontDark;
    }
    .external-links {
      font-size: 18px;
      @media (max-width: $xxl) {
        font-size: 14px;
      }
    }
  }
  .cart-wrapper {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    column-gap: 12px !important;
    a {
      display: flex;
      padding: 0;
    }

    .cart-icon-container {
      display: flex !important;
    }

    .user-icon {
      cursor: pointer;
      margin-left: 3px;
      &:lang(ar) {
        margin-left: 0;
      }
    }
    .favourite-icon {
      &:lang(ar) {
        margin-left: 3px;
      }
    }
  }
  @media (max-width: $xxl) {
    .cart-wrapper {
      .favourite-icon {
        svg {
          width: 18px;
        }
      }

      .user-icon {
        width: 16px;
      }
    }
  }
  @media (max-width: $xl) {
    .ant-btn {
      padding: 10px;
    }
  }
  @media (max-width: $lg) {
    padding: 0 20px;
  }
  @media (max-width: $md) {
    display: none;
  }
}
.brands-dropdown-menu {
  .ant-space {
    display: flex;
    flex-direction: column;
    .ant-btn {
      padding: 8px 20px;
    }
    .ant-typography {
      font-weight: 500;
      color: $fontDark;
      font-size: 16px;
      @media (max-width: $xxl) {
        font-size: 14px;
      }
    }
    .ant-list-item {
      padding: 0;
    }
  }
  max-height: 50vh;
}
.active {
  svg path {
    stroke: $selected !important;
    transform-origin: center !important;
  }
  > span {
    color: $selected !important;
  }
}
.ant-menu-vertical {
  .arrow {
    display: none;
  }
}
.categories-title {
  padding: 30px 20px 0;
  .ant-typography {
    color: $fontDark;
    font-weight: 600;
    font-size: 16px;
  }
  .ant-divider {
    margin-bottom: 5px;
  }
  @media (max-width: $xxl) {
    .ant-typography {
      font-size: 14px;
    }
  }
}
