@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.search-result-dropdown {
  display: flex;
  column-gap: 5px;
}
.search-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary;
  padding: 10px 60px 15px;
  column-gap: 20px;

  position: relative;
  z-index: 99;
  img {
    // height: 150px;
    max-width: 125px;
  }
  .search-bar {
    width: 50%;
  }
  .search-icon {
    display: none;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0;
  }
  .btns-wrapper {
    display: flex;
    column-gap: 20px;
    .authentication-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 15px;
      .authentication-btn {
        text-transform: uppercase;
        color: $white;
        width: 130px;
        border: solid 1.5px $white;
        border-radius: 35px !important;
        font-weight: 500;
        letter-spacing: 0.24px;
        text-align: center;
        font-size: 16px;
        padding: 10px 15px;
        @media (max-width: $xxl) {
          font-size: 14px;
          &:lang(ar) {
            font-size: 12px;
          }
          width: 100px;
          padding: 5px 0;
        }
      }
      .login-btn,
      .login-btn.ant-btn-text:hover,
      .login-btn.ant-btn-text:focus {
        background-color: $lightBrown !important;
        border-color: $lightBrown;
        color: $fontDark;
      }
    }
    .hotline-wrapper {
      display: flex;
      align-items: center;
      column-gap: 8px;
      .hotline {
        color: $lightGray;
        font-size: 22px;
      }
    }
  }
  @media (max-width: $xxl) {
    img {
      max-width: 110px;
    }
    .btns-wrapper {
      .hotline-wrapper {
        .hotline-icon {
          width: 22px;
        }
        .hotline {
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width: $lg) {
    padding: 10px 20px 15px;
  }
  @media (max-width: $md) {
    padding: 10px 20px;
    .search-icon {
      display: inline;
      color: $white;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    .search-icon {
      margin: 0px 15px;
      @media (max-width: $sm) {
        margin: 0 5px;
      }
    }
    img {
      height: 46px;
      margin: 0px;
      margin-left: 5vw;
      &:lang(ar) {
        margin-left: 0;
        margin-right: 5vw;
      }
    }
    .search-bar {
      display: none;
    }
  }
}
.ant-modal-root {
  .search-modal {
    top: 0;
    .ant-select-auto-complete {
      width: 100%;
    }
  }
}
