@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.ant-form {
  .ant-form-item {
    margin-bottom: 10px;
  }
  button[type='submit'] {
    margin-top: 20px;
    width: 100%;
    background-color: $greenButton;
    border-color: $greenButton;
    border-radius: 6px !important;
  }

  .ant-form-item-control-input-content {
    input {
      height: 50px;
    }

    .ant-input-affix-wrapper input {
      height: 40px;
    }
  }
}

.ant-form-item-label {
  display: block;
  width: 100%;

  label {
    width: 100%;
    color: $fontDark;
    font-size: 16px;
  }
}

.ant-modal-confirm-content:lang(ar) {
  direction: rtl !important;
}
.address-form,
.national-id-form {
  button[type='submit'] {
    width: auto;
  }
}
