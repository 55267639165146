@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.filter-container {
  .filter-info-wrapper {
    .filter-title {
      font-size: 20px;
      color: $fontDark;
      @media (max-width: $xxl) {
        font-size: 18px;
      }
    }
    .list-infinite-container {
      max-height: 200px;
      overflow: auto;
      margin-top: 20px;
    }
    .list-infinite-container::-webkit-scrollbar {
      width: 3px;
      height: 5px;
    }
    .list-infinite-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $borderColor;
      border: 1px solid $borderColor;
    }
    .price-range-wrapper {
      padding: 5px;
      margin-bottom: 35px;
      .high-price {
        display: flex;
        justify-content: flex-end;
      }
      .price-wrapper {
        flex-direction: column;
        align-items: flex-start;
        span {
          color: $darkGray;
        }
      }

      .slider-wrapper {
        margin-top: 15px;
      }
      .ant-input-number {
        color: $primary;
        font-size: 18px;
        border: 1px solid $primary;
        border-radius: 4px;
        width: 100px;
        input {
          &::placeholder {
            color: $primary;
            font-size: 18px;
          }
        }
      }
    }
    .filter-category {
      margin-top: 10px;
    }
    .ant-divider-horizontal {
      margin: 5px 0;
    }

    .ant-select {
      width: 100%;
    }
  }
  .custom-button {
    height: 50px;
  }
  @media (max-width: $md) {
    max-width: 600px;
  }
}
.filter-container .filter-info-wrapper .list-infinite-container::-webkit-scrollbar-thumb {
  background-color: $primary;
  border: 1px solid $primary;
}

@media (max-width: $xl) {
  .filter-container {
    .filter-info-wrapper {
      padding: 20px 0;
      .price-range-wrapper {
        .ant-input-number {
          width: 70px;
        }
      }
    }
  }
}
@media (max-width: $lg) {
  .filter-container {
    .filter-info-wrapper {
      padding: 50px 20px 20px;
      .price-range-wrapper {
        .ant-input-number {
          width: 100px;
        }
      }
    }
  }
}
