@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.order-summary {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  // top: 150px;
  @media (max-width: $lg) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .ant-typography {
    color: $fontDark;
    font-size: 18px;
    @media (max-width: $xxl) {
      font-size: 16px;
    }
  }
  z-index: 1;
  .summary-action-buttons-wrapper {
    justify-content: center !important;
    .summary-action-buttons {
      background-color: $greenButton;
      text-transform: uppercase;
      color: $white;
      border-color: $greenButton;
      border-radius: 5px !important;
      padding: 10px 130px;
      margin-top: 20px;
      &:hover,
      &:focus {
        background-color: $greenButton;
        border-color: $greenButton;
      }
      @media (max-width: $xxl) {
        padding: 10px 100px;
      }
      @media (max-width: $xl) {
        padding: 10px 50px;
      }
    }
  }
  .products-summary {
    color: $fontDark;
    font-size: 20px;
    font-weight: 500;
    @media (max-width: $lg) {
      margin-top: 0;
    }
  }
  .products-list-wrapper {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .ant-list {
      max-height: 410px;
      overflow-y: auto;
    }
  }
  @media (max-width: $lg) {
    .products-list-wrapper {
      margin-bottom: 0;
      margin-top: 25px;
    }
  }
}
