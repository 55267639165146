$primary: #2e3192;
$secondary: #fcfcfc;
$selected: #33a0fc;
$fontDark: #373737;

$white: #ffffff;
$black: #000000;

$blue: #3135a5;
$darkGray: #aaaaaa;
$darkBlue: #1a2171;
$lightGray: #ebebeb;

$green: #1ac748;
$redishRose: #ff4858;

$greyBackground: #fafafa;
$lightBrown: #fdd166;
$greenButton: #2ecc71;

$borderColor: #d4d4d4;
$greenBorderColor:#1ea858;
$greyBorderColor: #dedede;
$darkGrayText: #475156;
$blackText: #191c1f;

