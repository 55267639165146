@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.payment-method-form {
  .ant-spin {
    margin: 0 20px;
    line-height: 0;
  }
  @media (max-width: $sm) {
    .ant-collapse-item.ant-collapse-item-active.ant-collapse-no-arrow {
      transform: scaleX(0.75);
      margin: 0px -50px;
    }
  }
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .payment-methods {
    color: $fontDark;
    font-size: 20px;
    font-weight: 500;
    // margin-top: -50px;
    @media (max-width: $lg) {
      margin-top: 0;
    }
  }
  .ant-radio-group {
    width: 100% !important;
  }
  .ant-radio-wrapper:first-child {
    margin-bottom: 0;
  }
  .ant-form-item-label label {
    color: black;
  }

  .ant-radio-wrapper {
    margin: 20px 0 0;
    display: block !important;
    border: 1px solid $borderColor;
    padding: 10px;
    border-radius: 5px;
  }
  .ant-radio-wrapper:nth-child(1) {
    margin: 0;
  }
  .ant-collapse-header {
    display: contents;
  }
  .loader {
    text-align: center;
  }
  .ant-select {
    min-width: 250px;
  }
  .bank-select-wrapper {
    margin: 20px 0 20px;
    .ant-select-selector {
      border-color: $borderColor;
      border-radius: 5px !important;
      height: 40px !important;
      padding: 3px 11px 0 !important;
    }
  }
  .checkbox-container {
    margin: 30px 0;
    .ant-checkbox-wrapper {
      font-size: 16px;
      color: black;
    }
    a {
      font-size: 16px;
      margin: 0 5px;
      color: $selected;
    }
  }
  .button-wrapper {
    @media (max-width: $sm) {
      display: flex;
      justify-content: center;
    }
  }
  .place-order-btn {
    background-color: $greenButton;
    text-transform: uppercase;
    color: $white;
    border-color: $greenButton;
    border-radius: 5px !important;
    padding: 10px 130px;
    margin-top: 20px;
    &:hover,
    &:focus {
      background-color: $greenButton;
      border-color: $greenButton;
    }
    @media (max-width: $xxl) {
      padding: 10px 100px;
    }
    @media (max-width: $xl) {
      padding: 10px 50px;
    }
  }
  @media (max-width: $sm) {
    margin-top: 0;
    .ant-select {
      width: 100%;
    }
    .bank-select-wrapper {
      margin: 15px 0px 20px;
    }
    .ant-radio-wrapper:first-child {
      margin-top: 10px;
    }
  }
}
