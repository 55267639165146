@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.site-layout {
  background-color: $greyBackground;
  height: 100%;
  min-height: calc(100vh - 220px);
  overflow: hidden;
  background-color: $greyBackground;

  padding: 255px 60px 40px;
  @media (max-width: $xxl) {
    padding: 255px 40px 40px;
  }
  @media (max-width: $lg) {
    & {
      padding: 215px 40px 20px;
    }
  }
  @media (max-width: $md) {
    padding: 100px 20px 20px;
  }
  @media (max-width: $sm) {
    padding-top: 65px 20px 0;
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
  overflow: hidden;
}

.fade-enter-active {
  opacity: 1;
  z-index: 1;
  transition: opacity 1s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in;
  display: none;
}
