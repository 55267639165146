@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.payment-screen {
  .ant-space {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    .ant-space-item {
      color: $fontDark;
      font-size: 30px;
      text-align: center;
    }
  }
  @media (max-width: $xxl) {
    margin-top: -20px;
    .ant-space {
      .ant-space-item {
        font-size: 25px;
        @media (max-width: $xs) {
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width: $lg) {
    margin-top: 0;
  }
  .product-preview {
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    img {
      padding: 10px;
      border: 1px solid $borderColor;
      border-radius: 5px;
    }
  }
  .button-wrapper {
    text-align: center;
  }
}
.payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
  .section-description {
    text-align: center;
  }
  .section-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
  }
  .order-details,
  .products-details {
    .ant-list-items {
      display: flex;
      column-gap: 10px;
    }
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    img {
      width: auto;
      height: 140px;
    }
  }
  .purchase-history-btn {
    background-color: $white;
    text-transform: uppercase;
    color: $primary;
    border-color: $primary;
    border-radius: 5px !important;
    padding: 10px 70px;
    margin-top: 20px;
    font-weight: 600;
    &:hover,
    &:focus {
      background-color: $primary;
      border-color: $primary;
    }
    @media (max-width: $xxs) {
      padding: 10px 50px;
    }
  }
}
.products-details {
  .order-details-item {
    .ant-col {
      .ant-list {
        .ant-row {
          column-gap: 10px;
          @media (max-width:$lg) {
            column-gap: 0;
            justify-content: center;
          }
          div {
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
