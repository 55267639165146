@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.cart-link-button {
  .ant-typography {
    margin: 0 5px;
    color: $fontDark;
  }

  .cart-icon-wrapper {
    position: relative;
    display: inline-block;

    .cart-icon-container {
      padding: 5px;
    }

    .ant-tag {
      background-color: $greyBackground;
      border-radius: 50%;
      top: -8px;
      right: -8px;
      display: block;
      position: absolute;
      margin: 0;
    }
  }

  @media (max-width: $xxl) {
    .cart-icon-wrapper {
      svg {
        width: 22px;
      }
      .ant-tag {
        line-height: 15px;
        padding: 0 5px;
        top: -3px;
        right: -6px;
      }
    }
  }
  @media (max-width: $md) {
    .ant-typography {
      display: none;
    }
    .cart-icon-wrapper {
      padding: 15px 10px 15px 0;
      .ant-tag {
        top: 8px;
        right: 5px;
      }
    }
  }
}
