@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.bordered-section-wrapper {
  background-color: $white;
  padding: 24px;
  border: 1px solid $borderColor;
  border-radius: 10px;
  .icon-title-wrapper {
    display: flex;
    align-items: baseline;
    column-gap: 10px;
    &:lang(ar) {
      .anticon {
        transform: rotate(180deg);
      }
    }
  }
  .icon-title-wrapper {
    .ant-space-item {
      color: $fontDark;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .section-description {
    color: $fontDark;
    font-weight: 600;
    font-size: 30px;
  }
  .ant-typography.ant-typography-success {
    color: #00e092;
  }
}

@media screen and (max-width: $xxl) {
  .bordered-section-wrapper {
    padding: 20px;
    .icon-title-wrapper {
      .ant-space-item {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: $md) {
  .bordered-section-wrapper {
    padding: 15px;
  }
}
