@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.my-account-wrapper {
  table {
    th {
      background-color: #e1f0fa;
      border: 1px solid #aacee8;
      border-left: none;
      text-align: center;
      overflow-wrap: normal;
      font-weight: bold;
      padding: 13px 6px;
      &:nth-child(1) {
        border-left: 1px solid #aacee8;
      }
      &:lang(ar) {
        border-left: 1px solid #aacee8;
        border-right: none;
        text-align: center;
        &:nth-child(1) {
          border-right: 1px solid #aacee8;
        }
      }
    }
    td {
      padding: 12px 10px;
      background-color: transparent;
      text-align: center;
      border: 1px solid #aacee8;
      border-top: none;
      border-left: none;
      color: $fontDark;
      overflow-wrap: break-word;
      &:nth-child(1) {
        border-left: 1px solid #aacee8;
      }
      &:lang(ar) {
        border-left: 1px solid #aacee8;
        border-right: none;
        &:nth-child(1) {
          border-right: 1px solid #aacee8;
        }
      }
      @media (max-width: $lg) {
        padding: 12px 5px;
      }
    }
    tr:last-child {
      td {
        border-bottom: 10px solid #aacee8;
      }
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 5px;
    &:lang(ar) {
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
    }
  }
  .ant-table-container table > thead > tr:last-child th:last-child {
    border-top-right-radius: 5px;
    &:lang(ar) {
      border-top-right-radius: 0;
      border-top-left-radius: 5px;
    }
  }
  .ant-table-container table > tbody > tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
    &:lang(ar) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 5px;
    }
  }
  .ant-table-container table > tbody > tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
    &:lang(ar) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 5px;
    }
  }
  @media (max-width: $sm) {
    table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}
