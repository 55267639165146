@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.wishlist-product-preview-wrapper {
  border-bottom: 1px solid $greyBorderColor;
  padding: 15px 0;
  .ant-card {
    background-color: transparent;
  }
  .ant-row {
    margin: 0 !important;
    row-gap: 10px;
    flex-direction: row !important;
    column-gap: 10px;
    justify-content: space-between;
  }
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background: transparent;
  }
  .ant-col {
    padding: 0 !important;
    display: flex;
  }
  .details-name {
    .remove-btn {
      svg {
        width: 25px; 
        @media (max-width:$xxl) {
          width: 22px;
        }
        path {
          stroke: red !important; 
          
        }
      }
    }
  }

  .details-addToCart {
    .ant-col {
      justify-content: flex-start;
    }
    .ant-col:last-child {
      justify-content: flex-end;
    }

    .price-container {
      .ant-space {
        flex-direction: column;
      }
    }
    .add-to-cart {
      margin-top: 0;
    }
  }
  .details-name,
  .details-addToCart {
    display: flex;
    align-items: center;
    padding: 0 !important;
    justify-content: space-between;

    .product-name {
      color: $fontDark;
      font-size: 16px;
      font-weight: 500;
      max-width: 300px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: $xxl) {
        font-size: 14px;
        max-width: 250px;
      }
      @media (max-width: $xxs) {
        max-width: 170px;
      }
    }
  }
  .ant-card-bordered {
    border: none;
  }

  .ant-card-body {
    padding: 0;
    .product-link {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 10px;
      .tablet-price-container{
        justify-content: center;
        .price-container{
          .ant-space{
            column-gap: 5px;
          }
        }
      }
    }
    img {
      object-fit: contain;
      max-width: 100%;
      height: 100px;
    }
  }

  @media (max-width: $lg) {
    .ant-card-body {
      img {
        width: 100%;
        min-height: 150px;
      }
    }
    .details-addToCart {
      .ant-col {
        justify-content: flex-start;
      }
    }
    .details-addToCart {
      justify-content: flex-end;
    }
  }
  @media (max-width: $md) {
    .details-name {
      .product-name-wrapper {
        display: flex;
        .ant-typography{
          margin-bottom: 0 !important;
        }
      }
    }

    .ant-card-body {
      img {
        width: 100%;
        min-height: 100px;
      }
    }
  
  }
  @media (max-width: $sm) {
    .ant-row {
      flex-direction: column !important;
    }
    .price-container {
      .ant-space {
        flex-direction: column;
      }
    }
    
  }
}
