@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.branch-card-wrapper {
  border-radius: 10px !important;
  border-color: $greyBorderColor !important;
  .ant-card-body {
    padding: 15px;
  }
  .ant-card-cover {
    height: 200px;
    flex-grow: 1 !important;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .branch-description-wrapper {
    display: flex;
    flex-direction: column;
  }
  .card-title {
    color: $blackText !important;
    height: 40px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .phone-number span,
  .hotline {
    height: 40px !important;
    color: $darkGrayText !important;
    font-weight: bold !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    margin-bottom: -10px;
  }
  .address-title span {
    color: $darkGrayText !important;
    height: 65px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 250px;
  }
  @media (max-width: $xxl) {
    .ant-card-body {
      padding: 20px 15px;
    }
    .phone-number span,
    .card-title {
      height: 30px !important;
    }
  }
  @media (max-width: $lg) {
    .address-title span {
      width: 200px;
    }
  }
  @media (max-width: $md) {
    .address-title span {
      height: 55px !important;
    }
  }
  @media (max-width: $sm) {
    .ant-card-body {
      padding: 20px 15px;
    }
    .address-title span {
      width: 380px;
    }
    .address-title span,
    .phone-number span,
    .card-title {
      height: auto !important;
    }
  }
  @media (max-width: $xs) {
    .address-title span {
      width: 300px;
    }
  }
  @media (max-width: '375px') {
    .address-title span {
      width: 245px;
    }
  }
}
