@import 'theme/colors.scss';

.card-label-wrapper {
  line-height: initial;
  // position: absolute;
  
  .ant-tag {
    width: 100%;
    display: inline-grid;
    text-align: center;
    background-color: $redishRose;
    color: white;
    margin: 0 0 3px;
    border: none;
    vertical-align: top;
    padding: 5px;
    border-radius: 0;
    line-height: initial;
  }
}
