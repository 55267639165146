@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.sort-by-wrapper {
  padding: 10px !important;
  .ant-dropdown-menu-item {
    text-align: center !important;
    border-bottom: 1px solid $borderColor;
    padding: 10px;
  }
}

.sort-btn {
  border-radius: 4px !important;
  padding: 7px 10px 5px !important;
  width: 200px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-weight: 500 !important;
  border: solid 1px $borderColor !important;
  span.ant-typography {
    font-size: 16px;
    @media (max-width: $xxl) {
      font-size: 14px !important;
    }
  }
}
