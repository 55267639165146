@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.categories-dropdown-menu {
  max-height: 50vh;
  max-width: 55vw;
  margin-bottom: 5px;
  .ant-space {
    display: flex;
    flex-wrap: wrap;
    .ant-typography {
      color: $black;
      font-weight: 500;
      font-size: 16px;
      @media (max-width: $xxl) {
        font-size: 14px;
      }
    }
    .ant-typography-secondary {
      color: $fontDark;
      font-size: 16px;
      @media (max-width: $xxl) {
        font-size: 14px;
      }
    }
    .ant-btn {
      padding: 8px 20px;
    }
    .ant-list-item {
      padding: 0;
    }
  }
}
