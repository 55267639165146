@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.about-us-wrapper {
  padding: 50px 0;
  .loader {
    text-align: center;
  }
  .section-title {
    padding-top: 20px;
    font-weight: bold;
  }
  .section-paragraph {
    margin-inline-end: 10px;
    font-size: 18px;
    color: $fontDark;
    @media (max-width: $xxl) {
      font-size: 16px;
    }
  }
  .image-wrapper {
    padding: 20px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .who-we-are {
    font-weight: bold !important;
    font-size: 12px;
    padding: 5px 10px;
    background-color: $primary;
    border-radius: 5px;
    color: $white;
  }
  .who-we-are-wrapper {
    padding: 30px 30px 0;
    background-color: $white;
    border: solid 1px $greyBorderColor;
    border-radius: 10px;
  }
  .our-branches-wrapper {
    padding-top: 20px;
    background-color: $greyBackground;
  }
}

@media (max-width: $md) {
  .about-us-wrapper {
    padding: 20px;
  }
}
@media (max-width: $xs) {
  .about-us-wrapper {
    padding: 20px 0;
  }
}
