@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

button.ant-btn,
button.ant-btn-lg,
button.ant-btn-sm,
button.ant-btn-icon-only {
  border-radius: 0px;
  padding: 10px 20px;
  height: auto;
}

button.ant-btn,
button.ant-btn-lg,
button.ant-btn-sm,
button.ant-btn-icon-only {
  border-radius: 0px;
  padding: 15px 20px;
  height: auto;
  @media (max-width: $xxl) {
    padding: 10px 20px;
  }
}
