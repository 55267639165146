@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.homepage {
  .loader {
    text-align: center;
  }
  .shop-now-wrapper {
    position: relative;
    img {
      width: 100%;
    }
    .shop-button {
      position: absolute;
      bottom: 100px;
      left: 90px;
      background-color: $selected;
      color: $white;
      border: none;
      padding: 10px 35px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 30px;
      transition: background-color 0.3s;
      z-index: 1;
      &:lang(ar) {
        left: auto;
        right: 90px;
      }
      @media (max-width: $xxl) {
        font-size: 16px;
      }
      @media (max-width: '1300px') {
        bottom: 50px;
        left: 4.8rem;
        &:lang(ar) {
          left: auto;
          right: 4.8rem;
        }
      }
      @media (max-width: $lg) {
        bottom: 120px;
        left: 3.5rem;
        padding: 8px 20px;
        &:lang(ar) {
          left: auto;
          right: 3.5rem;
        }
      }
      @media (max-width: $md) {
        bottom: 60px;
        left: 2.5rem;
        font-size: 14px;
        &:lang(ar) {
          left: auto;
          right: 2.5rem;
        }
      }
      @media (max-width: $sm) {
        bottom: 45px;
        left: 2rem;
        padding: 5px 12px;
        font-size: 12px;
        &:lang(ar) {
          left: auto;
          right: 2rem;
        }
      }
      @media (max-width: '375px') {
        left: 1.3rem;
        padding: 5px 12px 3px;
        font-size: 10px;
        &:lang(ar) {
          left: auto;
          right: 1.3rem;
        }
      }
    }
  }
  .homepage-carousel {
    position: sticky !important;
    @media (min-width: $xxl) {
      margin: -50px auto 0 -60px !important;
    }
    @media (max-width: $xxl) {
      margin: -65px auto 0 -40px !important;
    }
    @media (max-width: $lg) {
      margin: -25px auto 0 -40px !important;
    }
    @media (max-width: $md) {
      margin: -20px auto 0 -20px !important;
    }
    left: auto;

    width: 100vw !important;
    z-index: 2;

    .slick-track {
      height: 100%;
      div {
        height: 100%;
        img {
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }

  .homepage-carousel:lang(ar) {
    position: sticky !important;
    @media (min-width: $xxl) {
      margin: -50px -60px 0 0px !important;
    }
    @media (max-width: $xxl) {
      margin: -65px -40px 0px 0px !important;
    }
    @media (max-width: $lg) {
      margin: -25px -40px 0px 0px !important;
    }
    @media (max-width: $md) {
      margin: -20px -20px 0 0px !important;
    }
  }

  .homepage-screen-wrapper {
    margin-top: 0px !important;
    .explore-section {
      margin: 0 -60px;
      @media (max-width: $lg) {
        margin: 0 -40px !important;
      }
      @media (max-width: $md) {
        margin: 0 -20px !important;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .ant-row {
        margin: 0 !important;
        .ant-col {
          padding: 0 !important;
        }
      }
      .explore-fourth-image {
        img {
          aspect-ratio: 10/8.12;
        }
      }
      .shop-now-wrapper {
        position: relative;
        .shop-now-button {
          position: absolute;
          bottom: 25px;
          left: 35px;
          background-color: $white;
          color: $fontDark;
          border: none;
          padding: 8px 15px;
          cursor: pointer;
          font-size: 13px;
          text-transform: uppercase;
          border-radius: 30px;
          transition: background-color 0.3s;
          span {
            margin-top: 2px;
          }
          z-index: 1;
          &:lang(ar) {
            left: auto;
            right: 35px;
          }
          @media (max-width: '1300px') {
            font-size: 12px;
            padding: 5px 10px;
          }
          @media (max-width: $lg) {
            bottom: 20px;
            left: 15px;
            font-size: 12px;
            padding: 5px 10px;
            &:lang(ar) {
              left: auto;
              right: 15px;
            }
          }
          @media (max-width: $md) {
            bottom: 10px;
            left: 10px;
            font-size: 10px;
            &:lang(ar) {
              left: auto;
              right: 10px;
            }
          }
          @media (max-width: $sm) {
            bottom: 15px;
            padding: 5px 12px;
            font-size: 12px;
          }
          @media (max-width: '400px') {
            font-size: 10px;
          }
        }
      }
    }
    .category-carousel-wrapper {
      background-color: #f2f4f6;
      padding: 50px 60px 20px;
      margin: 0 -60px;
      @media (max-width: $xxl) {
        padding: 30px 60px 20px;
        margin: 0 -40px;
      }
      @media (max-width: $lg) {
        margin: 0 -40px;
      }
      @media (max-width: $md) {
        margin: 0 -20px;
      }
      .shop-title {
        font-size: 24px;
        color: $fontDark;
        font-weight: 600;
        margin-bottom: 30px;
        @media (max-width: $xxl) {
          font-size: 20px;
        }
        @media (max-width: $xs) {
          font-size: 16px;
        }
      }

      .slick-track {
        display: flex;
        column-gap: 30px;
        @media (max-width: $xs) {
          column-gap: 20px;
        }
      }
      .ant-list {
        .ant-row {
          justify-content: space-around;
          align-items: flex-start;
          padding-top: 10px;
        }
        .ant-col {
          display: flex;
          justify-content: center;
        }
        .category-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 64px;
            height: 64px;
            object-fit: contain;
          }
          p {
            font-size: 16px;
            color: $primary;
            margin-top: 10px;
            margin-bottom: 0;
            max-width: 120px;
            text-align: center;
            @media (max-width: $xxl) {
              font-size: 14px;
            }
            @media (max-width: $xs) {
              max-width: 80px;
            }
          }
          @media (max-width: $xs) {
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
    .homepage-screen {
      .ant-space {
        width: 100%;
      }
      .tabs-section {
        margin-top: 50px;
        @media (max-width: $sm) {
          margin-top: 30px;
        }
        .ant-list-item {
          margin-bottom: 20px;
          @media (max-width: $xxl) {
            margin-bottom: 15px;
          }
        }
      }
      .video-section {
        position: relative;
        margin: 0 -60px;
        margin-top: 50px;
        img {
          width: 100%;
          object-fit: cover;
        }
        @media (max-width: $xxl) {
          margin: 0 -40px;
          margin-top: 50px;
        }
        @media (max-width: $md) {
          margin: 0 -20px;
          margin-top: 30px;
          img {
            height: auto;
          }
          @media (max-width: $xs) {
            margin-top: 20px;
          }
        }
      }
      .promotions-section {
        .ant-spin {
          display: flex;
          justify-content: center;
        }
        .slick-track {
          display: flex;
          column-gap: 10px;
          @media (max-width: $xs) {
            column-gap: 5px;
          }
        }
        margin-top: 50px;
        @media (max-width: $md) {
          margin-top: 30px;
        }
        .offers-title {
          font-size: 24px;
          color: $fontDark;
          font-weight: 600;
          margin-bottom: 20px;
          @media (max-width: $xxl) {
            font-size: 20px;
          }
          @media (max-width: $xs) {
            font-size: 16px;
          }
        }
        .pagination {
          .ant-pagination {
            justify-content: center;
          }
        }
      }
      .brands-section {
        padding: 40px 0 0;
        .ant-row {
          justify-content: space-around;
          align-items: center;
        }
        .brand-item {
          img {
            width: 132px;
          }
        }
        @media (max-width: $xxl) {
          .brand-item {
            justify-content: center;
            img {
              width: 100px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $xxl) {
  .homepage {
    .homepage-carousel {
      top: 175px;
    }
    .homepage-screen-wrapper {
      margin-top: 65vh;
    }
  }
}
@media (max-width: '1400px') {
  .homepage {
    .homepage-screen-wrapper {
      margin-top: 62vh;
    }
  }
}
@media (max-width: '1300px') {
  .homepage {
    .homepage-screen-wrapper {
      margin-top: 57vh;
    }
  }
}
@media (max-width: $lg) {
  .homepage {
    .homepage-screen-wrapper {
      margin-top: 59vh;
    }
  }
}
@media (max-width: $md) {
  .homepage {
    .homepage-carousel {
      top: 75px;
    }
    .homepage-screen-wrapper {
      margin-top: 35vh;
      .category-carousel-wrapper {
        padding: 30px 0;
        padding-left: 40px;
        padding-right: 45px;
        &:lang(ar) {
          padding-left: 45px;
          padding-right: 40px;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .homepage {
    .homepage-screen-wrapper {
      margin-top: 175px;
      .category-carousel-wrapper {
        padding: 20px 0;
        padding-left: 20px;
        padding-right: 25px;
        &:lang(ar) {
          padding-left: 25px;
          padding-right: 20px;
        }
      }
    }
  }
}
