@import 'theme/colors.scss';

.ant-pagination {
  display: flex;
  text-align: center;
  justify-content: center;
  li {
    background: transparent;
  }
  svg {
    color: $primary;
  }

  .ant-pagination-item {
    border: 1px solid $primary;
    border-radius: 5px;
    font-weight: 600;
  }
  .ant-pagination-item-active {
    background-color: $selected;
    border-color: $selected;
    border-radius: 5px;
    a {
      color: $white;
    }
  }

  .ant-pagination-prev button.ant-pagination-item-link,
  .ant-pagination-next button.ant-pagination-item-link {
    border: none;
    background: transparent;
  }
  .ant-pagination-item-link {
    .anticon {
      vertical-align: middle;
    }
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-pagination-disabled {
    display: none;
  }
}
