@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.price.ant-typography {
  font-weight: bold;
  font-size: 20px;
  &.price {
    color: $fontDark;
  }
  del {
    color: $darkGray;
  }
  @media (max-width: $xxl) {
    font-size: 18px;
  }
  @media (max-width: $md) {
    font-size: 14px;
  }
}
