@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.account-tab {
  .account-title {
    font-size: 20px;
    color: $fontDark;
    font-weight: 600;
    @media (max-width:$xxl) {
      font-size: 18px;
    }
  }
  .ant-space {
    width: 100%;
  }
  .account-data {
    margin-top: 20px;
  }
  .ant-row {
    column-gap: 20px;
    @media (max-width: $xs) {
      justify-content: center;
    }
    .account-btns {
      margin-top: 10px;
      padding: 7px 0;
      width: 150px;
      border-radius: 6px !important;
      background-color: $white;
      color: $selected;
      border-color: $selected;
      &:hover{
        border: none;
        background-color: $selected;
        color: white;
      }
    }
  }
}
