@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.single-product {
  .product-title {
    color: $fontDark;
    font-size: 30px;
    font-weight: 500;
  }
  .product-details-wrapper {
    margin-top: 40px;
    .ant-space-item {
      margin-bottom: 15px !important;
    }
    .product-price-wrapper {
      .price.ant-typography {
        font-size: 30px;
      }
      .price.ant-typography del {
        font-size: 25px;
      }
      .ant-space {
        column-gap: 10px;
      }
    }
  }

  .image-gallery-wrapper {
    max-height: 500px;
    height: 500px;
    .image-gallery-image {
      height: 100%;
      cursor: pointer;
      width: 100%;
      padding: 15px;
      border: solid 1px $borderColor;
      border-radius: 10px;
      background-color: white !important;
      @media (max-width: $sm) {
        width: 100%;
      }
    }
  }
  .image-gallery-thumbnails-wrapper {
    margin-top: 5px;
  }
  .image-gallery {
    height: inherit;
    position: relative;
    div {
      height: inherit;
    }
    .ant-btn-link {
      display: none;

      &:hover {
        transform: scale(1.1);
      }

      svg {
        color: white;
        width: 25px;
        height: 25px;
      }
    }
    .image-gallery-thumbnail .image-gallery-thumbnail-image {
      padding: 5px;
      border: solid 1px $borderColor;
      border-radius: 5px;
      width: 80px;
      height: 80px;
      object-fit: contain;
      cursor: pointer;
    }
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:hover,
    .image-gallery-thumbnail:focus {
      border: none;
      border-width: 0;
    }
    .image-gallery-thumbnails-container {
      padding-top: 10px;
      button {
        border: none;
        margin-bottom: 20px;
        transition: all 0.2s ease-in-out;

        &:hover,
        &:focus,
        &.active {
          transform: scale(1.05);
        }
        @media screen and (max-width: 576px) {
          & {
            margin-bottom: 0;
            margin: 0 5px;
          }
        }
      }
    }
  }

  .product-detail-wrapper {
    width: 50%;
    display: flex;
    justify-content: space-between;
    .product-detail {
      color: #666666;
      font-weight: normal;
    }
    .ant-col:last-child {
      display: flex;
      justify-content: flex-end;
      @media (max-width: $xs) {
        justify-content: flex-start;
      }
    }
    @media (max-width: $md) {
      width: 100%;
    }
    @media (max-width: $xxs) {
      column-gap: 10px;
    }
  }

  .lb-header,
  .mobile-controls {
    &:lang(ar) {
      .prev,
      .next {
        rotate: 180deg;
      }
    }
  }
}

.single-product-thumbnails {
  width: 100px;
  height: 100px;
}

.description {
  color: $fontDark !important;
  font-size: 18px !important;
}

.add-to-favorite-wrapper {
  align-items: center !important;
}

.stock-wrapper {
  margin-left: 30px;
  padding: 5px 20px;
  border: solid 1px $borderColor;
  border-radius: 5px;
  background-color: $white;
  &:lang(ar) {
    margin-right: 0;
    margin-right: 30px;
  }
}

.stock-ico {
  width: 10px;
  margin-right: 10px;
  &:lang(ar) {
    margin-right: 0;
    margin-left: 10px;
  }
}

.specs-wrapper {
  padding: 50px 60px;
  background-color: $white;
  margin: 120px -60px 0;
  h1.ant-typography {
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: bold;
    color: $fontDark;
  }
  .product-detail {
    font-size: 18px;
  }
  @media (max-width: $xxl) {
    margin: 100px -40px 0;
    padding: 30px 40px;
    h1.ant-typography {
      font-size: 20px;
    }
    .product-detail {
      font-size: 16px;
    }
  }
  @media (max-width: $md) {
    padding: 20px;
    margin: 0 -20px;
  }
}
@media screen and (max-width: $xxl) {
  .single-product {
    .product-title {
      font-size: 25px;
    }
    .product-details-wrapper {
      margin-top: 35px;
      .ant-space-item {
        margin-bottom: 10px !important;
      }
      .product-price-wrapper {
        .price.ant-typography {
          font-size: 25px;
        }
        .price.ant-typography del {
          font-size: 20px;
        }
      }
    }
    .image-gallery-wrapper {
      max-height: 430px;
      height: 430px;
      .image-gallery-image {
        height: 100%;
      }
    }
  }
  .description {
    font-size: 16px !important;
  }
  .stock-ico {
    width: 8px;
  }
}
@media screen and (max-width: $lg) {
  .single-product {
    margin-top: 160px;
    .product-details-wrapper {
      margin-top: 30px;
      padding: 0px 30px;
      .product-price-wrapper {
        .price.ant-typography {
          font-size: 18px;
        }
        .price.ant-typography del {
          font-size: 16px;
        }
      }
    }
  }
}
@media screen and (max-width: $md) {
  .single-product {
    margin-top: 30px;
    .product-title {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: $sm) {
  .single-product {
    margin-top: 0;
    .single-product-wrapper {
      justify-content: center;
    }
    .single-product {
      .product-detail-wrapper {
        width: 100%;
        display: flex;
      }
    }

    .image-gallery-slides {
      margin-top: 50px;
    }

    .image-gallery-thumbnails-container {
      margin-top: 30px;
    }
    .product-details-wrapper {
      margin-top: 230px;
      padding: 0px 30px;
    }
  }
}
