@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.product-screen {
  width: 100%;
}

// .ant-space-item .ant-typography.description{
//   @media (max-width: $sm) {  
//   display: inline-block !important;
//   width: 50% !important;
//   }
// }

.related-products-wrapper {
  border: 0px !important;
  background-color: transparent !important;
  @media (max-width:$xxl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.ant-space.ant-space-vertical{
  display: flex !important;
}

.related-products-wrapper .ant-space-item {
  color: $fontDark ;
  font-size: 24px;
  @media (max-width:$xxl) {
    font-size: 20px;
  }
}

.slider-wrapper {
  justify-content: space-between !important;
  @media (max-width: $xs) {
    justify-content: center !important;
  }
}
