@import 'theme/colors.scss';

.ant-btn-text:hover, .ant-btn-text:focus {
  background: transparent !important;
}

.filter-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $fontDark;
  font-weight: 500;
  font-size: 20px;
}
.ant-divider{
  border-color: $darkGray !important;
}