@import 'theme/antd-custom-theme.css';
@import 'theme/typography.scss';
@import 'theme/global.scss';

@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

@font-face {
  font-family: "Almarai";
  src: url("./assets/fonts/Almarai-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "Almarai";
  src: url("./assets/fonts/Almarai-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
  font-style: bolder;
}

@font-face {
  font-family: "Almarai";
  src: url("./assets/fonts/Almarai-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: lighter;
}

@font-face {
  font-family: "Almarai";
  src: url("./assets/fonts/Almarai-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-size: 20px;
  color: $primary;
}

@media (max-width: $md) {
  body {
    font-size: 18px;
  }
}
