@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.my-account-wrapper {
  display: flex;
  flex-direction: column;
  .my-account-title {
    font-size: 25px;
    color: $fontDark;
    font-weight: 600;
    margin-bottom: 20px;
    @media (max-width:$xxl) {
      font-size: 20px;
    }
  }
  .ant-row {
    width: 100%;
  }
  .tabs-section-wrapper {
    padding-right: 2px;

    &:lang(ar) {
      padding-left: 2px;
    }
    .tabs-wrapper {
      border-radius: 5px 0 0 5px;
      &:lang(ar) {
        border-radius: 0 5px 5px 0;
      }
    }
  }
  .tabs-section-wrapper .tabs-wrapper,
  .tab-content {
    background-color: $white;
    border: 1px solid $borderColor;
    padding: 20px;
    @media (max-width: $md) {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
  .tab-content {
    border-radius: 0 5px 5px 0;
    &:lang(ar) {
      border-radius: 5px 0 0 5px;
    }
  }
}

@media (max-width: $md) {
  .my-account-wrapper {
    flex-direction: column;

    .ant-row {
      // flex-direction: column;
      width: auto;
      .tabs-section-wrapper,
      .tab-content-wrapper {
        max-width: 100%;
      }
      .tabs-section-wrapper .tabs-wrapper {
        border: none;
        background-color: transparent;
        padding: 20px 0;
      }
      .tab-content {
        border-radius: 5px;
      }
    }
  }
}
