@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';

.map-main-wrapper {
  h1.ant-typography {
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  .map-current-location,
  .map-current-location-rtl {
    bottom: 70px;
    height: 40px;
    width: 40px;
    border-radius: 20px !important;
    padding: 0px;
    box-shadow: 0px 3px 5px 0px $borderColor;
  }
  .map-current-location {
    left: 20px;
  }
  .map-current-location-rtl {
    right: 20px;
  }
}

.custom-input {
  width: 60%;
  margin: 20px auto;
  left: 0;
  right: 0;
}

.custom-placeholder {
  background-color: $primary;
  max-width: 400px;
  padding: 8px 10px;
  border-radius: 8px;
  transform: translateY(-110px) translateX(-40px);
  span.ant-typography {
    color: $white;
  }
  span.ant-typography::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 10%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: $primary transparent transparent transparent;
  }
}

@media (max-width: $sm) {
  .custom-input {
    width: 80%;
  }
  .custom-placeholder {
    max-width: 200px;
    padding: 4px 5px;
  }
}
