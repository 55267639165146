@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.change-password-wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 20px;
  svg {
    path {
      fill: $selected;
    }
  }
  &:lang(ar) {
    .anticon {
      transform: rotate(180deg);
    }
  }
  .change-password-title {
    font-size: 20px;
    color: $fontDark;
    font-weight: 600;
    @media (max-width: $xxl) {
      font-size: 18px;
    }
  }
}
