@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.order-tab {
  .orders-title {
    font-size: 20px;
    color: $fontDark;
    font-weight: 600;
    margin-bottom: 20px;
    @media (max-width:$xxl) {
      font-size: 18px;
    }
  }
  .order-list {
    margin-bottom: 10px;
  }
  .bordered-section-wrapper {
    width: 100%;
  }
  .action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: $fontDark;
    cursor: pointer;
    &:focus {
      background-color: transparent;
      color: $fontDark;
    }
    &:hover {
      background-color: transparent;
      color: $selected;
      svg {
        path {
          fill: $selected;
        }
      }
    }
    @media (max-width: $lg) {
      column-gap: 5px;
      font-size: 13px;
    }
  }
}
