@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.wishlist-wrapper {
  .wishlist-title {
    font-size: 20px;
    color: $fontDark;
    font-weight: 600;
    margin-bottom: 20px;
    @media (max-width: $xxl) {
      font-size: 18px;
    }
  }

  .wishlist {
    .ant-list-items {
      .wishlist-product-preview-wrapper:last-child {
        border-bottom: none;
      }
    }
  }
}
