@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.homepage {
  .homepage-carousel-wrapper {
    .ant-carousel .slick-dots {
      margin-left: 0;
      margin-right: 0;
    }
    .ant-carousel .slick-dots li,
    .ant-carousel .slick-dots li button {
      width: 12px !important;
      height: 12px !important;
      border-radius: 50px !important;
      border: 1px solid $white;
      background: transparent;
    }
    .ant-carousel .slick-dots .slick-active button {
      background: $white;
    }
    .slick-dots li button:before {
      display: none;
    }
    .ant-carousel .slick-dots-bottom {
      bottom: 30px;
      @media (max-width: $md) {
        bottom: 12px;
      }
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
    @media (max-width: $xxl) {
      font-size: 25px;
    }
  }
}
