@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.steps-wrapper {
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-weight: bold !important;
  }
  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $borderColor !important;
  }
  .ant-steps-item-title {
    line-height: 25px;
  }
  .ant-steps-item-title::after {
    background: repeating-linear-gradient(
      to right,
      #f0f0f0 0,
      #f0f0f0 5px,
      transparent 5px,
      transparent 10px
    ) !important;
  }
  .ant-steps-item-container {
    display: flex;
    align-items: center;
    .ant-steps-item-title::after {
      top: 25px;
    }
  }
}
.checkout-wrapper {
  margin-top: 70px !important;
  @media (max-width: $lg) {
    margin-top: 30px !important;
  }
}
.address-image {
  display: flex;
  justify-content: center;
  img {
    width: 60%;
    @media (max-width: $lg) {
      width: 40%;
    }
    @media (max-width: $sm) {
      width: 60%;
    }
  }
}

@media (max-width: $md) {
  .checkout-wrapper {
    margin: 30px 0 !important;
    .cart-summary {
      padding: 10px 20px !important;
    }
  }
  .ant-steps {
    padding: 30px 20px 0 !important;
  }
}

@media (max-width: $sm) {
  .steps-wrapper {
    .ant-steps-item-title {
      font-size: 18px;
    }
  }
}
