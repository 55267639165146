@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.burger-menu-wrapper {
  display: none !important;
  .ant-btn {
    padding: 4px;
    border-color: transparent !important;
    box-shadow: none;
    color: $white;
    background-color: transparent;
    &:hover,
    &:focus {
      background-color: transparent;
      color: $selected;
    }
  }
  @media (max-width: $md) {
    display: inline !important;
  }
}
.burger-section-title {
  .ant-menu-item-selected {
    background-color: transparent !important;
  }
  .ant-menu-item-group-title {
    color: white;
    background-color: $primary;
  }
  .subcategory-title {
    .ant-btn {
      width: 100%;
      text-align: start;
    }
    background-color: $greyBackground;
    border-bottom: 2px solid white;
    .ant-menu-item-group-title {
      padding: 0px !important;
      background-color: $greyBackground;
    }
    .subcategory-menu-item {
      padding-right: 15px !important;
      padding-left: 15px !important;
      border-bottom: 0px !important;

      .ant-typography {
        color: $fontDark;
      }
    }
  }
}
.ant-modal-root {
  .burger-menu-modal {
    top: 67px;
    width: 100% !important;
  }
}
.no-arrow {
  .ant-menu-submenu-arrow {
    display: none;
  }
}
