@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';


.ant-breadcrumb a {
  color: $fontDark !important;
  font-weight: 500;
  &:hover {
    color: $selected !important;
  }
}
.breadcrumbs {
  margin: 10px 0px 50px !important;
  a,
  span.ant-typography {
    color: $selected;
    font-size: 16px;
    font-weight: 500;
    @media (max-width:$xxl) {
      font-size: 14px;
    }
  }
}
