@import 'theme/breakpoints.scss';
@import 'theme/colors.scss';
.privacy-policy-wrapper {
  text-align: right;
  .icon-title-wrapper {
    justify-content: flex-end;
    &:lang(ar) {
      justify-content: flex-start;
    }
  }
  .paragraph {
    margin-top: 25px;
  }
  ul li {
    margin: 0 20px 10px 0 !important;
    padding: 0 4px 0 0 !important;
  }
}
