.address-form {
  margin-top: 20px !important;
  .button-wrapper {
    text-align: center;
  }
  .city-code-selector {
    width: 70px !important;
  }
  .number-type-selector {
    .ant-select-selector {
      height: 50px !important;
      align-items: center;
    }
  }
}
