@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.national-id-form {
  margin: 20px 10px !important;
  .submit-btn {
    background-color: $greenButton;
    border-color: $greenButton;
    border-radius: 5px !important;
    padding: 10px 70px;
    &:hover,
    &:focus {
      background-color: $greenButton;
      border-color: $greenButton;
    }
  }
  @media (max-width:$sm) {
    margin: 0 15px !important;
  }
}
