@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.site-footer {
  .promo-section {
    display: flex;
    max-height: 500px;
    background-color: $primary;
    padding: 50px 80px;
    overflow-y: visible;
    img {
      width: 80%;
      object-fit: cover;
    }
    .ant-typography {
      color: white;
    }
  }
  .ant-space-item > .exchange-button {
    border-radius: 0 !important;
    padding: 10px 20px;
    color: $primary;
    height: auto;
  }
  .site-footer-header {
    background-color: $primary;
    padding: 40px 60px;
    color: $white;
    button.ant-btn {
      padding: 0;
    }
    span {
      color: $white;
      text-transform: none;
      display: block;
    }
    a {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
    @media (max-width: $xxl) {
      padding: 20px 40px;
    }
    .account,
    .links,
    .contact-us {
      & > div {
        font-size: 16px;
        margin-bottom: 5px;
        @media (max-width: $xxl) {
          font-size: 14px;
        }
      }
      .ant-btn,
      span {
        font-size: 14px;
        @media (max-width: $xxl) {
          font-size: 12px;
        }
      }
      .ant-space {
        padding-right: 25%;
        @media (max-width: $xxl) {
          padding-right: 15%;
        }
        &:lang(ar) {
          padding-right: 0;
          padding-left: 20%;
          @media (max-width: $xxl) {
            padding-left: 10%;
          }
        }
      }
    }
    .account,
    .links,
    .contact-us {
      display: flex;
      .ant-divider-vertical {
        height: 12em;
        border-left: 1px solid #383dab;
        &:lang(ar) {
          border-left: none;
          border-right: 1px solid $white;
        }
        @media (max-width: 1024px) {
          display: none;
        }
      }
      svg {
        width: 12px;
        height: auto;
        &:lang(ar) {
          transform: rotate(180deg);
        }
      }
    }

    .follow-us {
      & > div {
        font-size: 16px;
        margin-bottom: 5px;
        @media (max-width: $xxl) {
          font-size: 14px;
        }
      }
      .ant-btn {
        font-size: 14px;
        @media (max-width: $xxl) {
          font-size: 12px;
        }
      }
      .ant-space-item {
        div {
          align-items: center;
        }
      }
      div {
        display: flex;
      }
      svg {
        width: 25px;
        height: 25px;
        @media (max-width: $xxl) {
          width: 20px;
          height: 20px;
        }
      }
    }
    .hotline-wrapper {
      display: flex;
      align-items: center;
      column-gap: 15px;
      svg {
        margin-top: 20px;
        @media (max-width: $xxl) {
          width: 25px;
        }
      }
      .hotline {
        font-size: 16px;
        letter-spacing: 0.16px;
        font-style: italic;
      }
      .hotline-number {
        font-size: 32px;
        font-weight: bold;
        @media (max-width: $xxl) {
          font-size: 27px;
        }
      }
    }
    .active {
      span {
        color: $selected;
      }
    }
    .contact-wrapper {
      display: flex;
      align-items: baseline;
      column-gap: 5px;
    }
  }

  .site-footer-bottom {
    background: $darkBlue;
    padding: 10px 60px;
    @media (max-width: $xxl) {
      padding: 7px 40px;
    }
    .tax-wrapper {
      .ant-space-item {
        margin-right: 8px !important;
        display: flex;
        &:lang(ar) {
          margin-right: 0 !important;
        }
      }
    }
    * {
      font-size: 12px;
      color: #f8f8f8;
      @media (max-width: $xxl) {
        font-size: 10px;
      }
    }
    .custom-whatsapp-button {
      vertical-align: -3px;
    }
  }
}

@media (max-width: $lg) {
  .site-footer {
    .promo-section {
      max-height: 400px;
      padding: 20px;
    }
  }
}

@media (max-width: $sm) {
  .site-footer {
    .site-footer-header {
      padding: 10px 30px 30px;
      text-align: center;
      .links {
        justify-content: flex-end;
      }
      .hotline {
        align-items: center;
      }
      .ant-space-vertical {
        text-align: left;
        &:lang(ar) {
          text-align: right;
        }
      }
      .ant-space-item {
        a,
        button {
          font-size: 13px;
          background: transparent;
        }
      }
      .hotline-wrapper {
        justify-content: flex-start;
        flex-direction: column;
      }
      .ant-row {
        justify-content: space-around;
      }
      .ant-col {
        margin-top: 50px;
      }
    }
    .mobile-social-media {
      background-color: $primary;
      padding: 10px 0;
      .follow-us {
        display: flex;
        justify-content: space-evenly;
        svg {
          width: 25px;
          height: 25px;
        }
        .ant-btn-text {
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
    }
    .contact-wrapper {
      .ant-typography {
        font-size: 13px;
      }
    }
    .promo-section {
      max-height: 250px;
      padding: 20px;
      img {
        width: 60%;
      }
    }
    .site-footer-bottom {
      padding: 10px 20px;
      text-align: center;
      .site-footer-bottom-wrapper {
        .copy-right {
          justify-content: center;
        }
      }
    }
    .ant-space-item {
      a {
        padding: 0;
      }
    }
  }
}
