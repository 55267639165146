@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.homepage-screen-wrapper {
  .ant-tabs-nav-list {
    column-gap: 20px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    color: $fontDark;
    font-weight: bold;
    border: none;
    padding: 8px 0;
    .ant-tabs-tab-btn {
      font-size: 18px;
      @media (max-width: $xl) {
        font-size: 16px;
      }
    }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background: transparent;
    border-bottom: 1px solid $selected;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $selected;
    font-weight: bold;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
}

.my-account-wrapper {
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: 1px solid $greyBorderColor;
    padding: 8px 0;
    padding-right: 25px;
    background: transparent;
    @media (max-width: $md) {
      padding-right: 10px;
    }
    &:lang(ar) {
      padding-right: 0;
      padding-left: 25px;
      @media (max-width: $md) {
        padding-left: 10px;
      }
    }
  }
  .ant-tabs-card > .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs-tab.ant-tabs-tab {
    border: none;
    border-bottom: 1px solid $fontDark;
  }
  .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    color: $fontDark;
    font-size: 16px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    border: none;
    border-bottom: 1px solid $selected;
    background-color: transparent;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $selected;
  }
  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    display: none;
  }
}

.auth-wrapper {
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-weight: bold !important;
    }
  }
  .ant-tabs-nav-list {
    width: 100% !important;
  }
  .ant-tabs-nav {
    margin: 0px !important;
  }

  .ant-tabs-nav-more {
    display: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: $selected !important;
    width: 50%;
    margin: 0px;
    border: 0px;
    justify-content: center;
  }
  .ant-tabs-tab {
    background-color: $lightGray !important;
    justify-content: center;
    width: 50%;
    margin: 0px;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 0px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: White;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab-btn {
    color: black;
    font-weight: bold;
  }
}
