@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .card-title {
    font-size: 16px;
    @media (max-width: $xxl) {
      font-size: 14px;
    }
  }
  .anticon {
    font-size: 30px;
  }
  .ant-carousel {
    width: 90%;
    .slick-dots {
      display: none !important;
    }
  }
  .slider-children-wrapper {
    padding: 10px;
  }
}
