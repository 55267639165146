@import 'theme/colors.scss';
@import 'theme/breakpoints.scss';

.address-item-wrapper {
  padding: 20px;
  width: 100%;
  border: 1px solid $borderColor;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
  .mobile-editable {
    justify-content: space-between;
  }
  .ant-radio-wrapper {
    font-size: 0px !important;
    &:lang(ar) {
      margin-left: 8px;
    }
  }
  .ant-typography {
    color: $fontDark;
  }
  .user-name {
    font-size: 18px !important;
    margin-bottom: 15px;
    @media (max-width: $xxl) {
      font-size: 16px;
    }
  }
  .ant-typography {
    font-size: 18px;
    @media (max-width: $xxl) {
      font-size: 16px;
    }
  }
  .edit-button,
  .delete-button {
    svg:hover {
      path {
        stroke: $selected !important;
        fill: $selected !important;
      }
    }
  }
  .address-info-wrapper {
    display: flex;
    flex-direction: column;
    .address-info {
      font-size: 16px;
      font-weight: 500;
      @media (max-width: $xxl) {
        font-size: 14px;
      }
    }
    .detailed-address {
      display: block;
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: $lg) {
        width: 200px;
      }
      @media (max-width: $sm) {
        width: 250px;
      }
    }
  }
  .edit-button,
  .delete-button {
    svg {
      width: 15px;
    }
    svg:hover {
      cursor: pointer;

      path {
        stroke: $selected !important;
        fill: $selected !important;
      }
    }
  }
  .address-action-btn {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    .ant-space-item {
      margin-left: 0 !important;
    }
    @media (max-width: $xs) {
      column-gap: 10px;
    }
  }
}
.address-action-btn {
  margin-top: 5px;
}
.checkout-item-wrapper {
  cursor: pointer;
  align-items: center;
  min-height: auto;
  .address-action-btn {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
  }
  &:hover {
    border-color: $selected;
  }
  &:hover {
    border-color: $selected;
  }
}
.active-address {
  border-color: $selected;
  background-color: $white;
}
.active-address {
  border-color: $selected;
}
